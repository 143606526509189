import React from 'react';
import './VideoMasthead.css';

const VideoMasthead = ({ videoUrl, phrase }) => {

  return (
    <div className="video-masthead">
      <video src={videoUrl} className="video-background" autoPlay loop muted playsInline preload="auto">
      </video>
      <div className="video-overlay">
        {phrase === 'home' ? (
          <p>Giving our clients an <span className="highlight">unfair advantage</span> since 1977</p>
        ) : (
          <p>{phrase}</p>
        )}
      </div>
    </div>
  );
};

export default VideoMasthead;
