// AutoPage.js
import React from 'react';
import VideoMasthead from '../../components/VideoMasthead'; // Update to import VideoMasthead
import './AutoPage.css';

const videoUrl = `https://pub-a6410c3681b541449845fc3f20fbc373.r2.dev/autoPageVideo.mp4`;

const AutoPage = () => {
  return (
    <div className="autoPageContainer">
      {/* Video Masthead Section */}
      <VideoMasthead 
        videoUrl={videoUrl}
        phrase="Automotive Marketing Expertise Since 1977" 
      />

      {/* Static Content for Auto Page */}
      <div className="contentSection">
        <h1>Automotive Marketing at Martin</h1>
        <div className="contentBlock">
          <h2>Strategy</h2>
          <p>
            Digital? Traditional? OTT? CTV? OLV? PPC? Social? Native? Not sure where to spend your dollars or what to say effectively?
            We are your guiding light to an effective and amplified go-to-market message.
          </p>
        </div>
        <div className="contentBlock">
          <h2>Accountability</h2>
          <p>
            One POINT OF CONTACT means all of your integrated marketing strategy and tactics come down to a single team.
            We track your objectives daily, weekly, monthly, annually, and if something is broken, WE FIX IT.
          </p>
        </div>
        <div className="contentBlock">
          <h2>Expertise</h2>
          <p>
            Our expertise can be seen, not just in our accolades, but in the success of our clients. Many of our current clients
            haven't just had record months, many of them are leaders in their markets. From creative to media to social we make
            sure your message is clear, cuts through, and is seen and heard by the right audience. Let us show you where decades
            of automotive expertise can take you.
          </p>
        </div>
      </div>

      {/* Turn-Key Agency Section */}
      <div className="turnKeySection">
        <h1>Martin as your Turn-Key Agency</h1>
        <p>
          Participation has its benefits. Dealers participating in GM's iMR programs enjoy competitively priced solutions with a range of benefits, including:
        </p>
        <ul>
          <li>Proven GM-certified solutions</li>
          <li>Integration with GM incentives</li>
          <li>Brand compliant templates & creative</li>
          <li>Tier 1, 2, and 3 alignment</li>
          <li>Heavy UPS & Marketing initiatives</li>
          <li>Funds reimbursement*</li>
        </ul>
        <p>
          *For programs that offer funds reimbursement. Percentages may vary.
        </p>
      </div>
    </div>
  );
};

export default AutoPage;
